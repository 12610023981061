import React, { useEffect, useState } from "react";

// /home/user/Downloads/newlaughMDZIP/src/Components/Delete_account/Delete_accounts.css
import "./Delete_accounts.css";
import {  useNavigate, useLocation } from "react-router-dom";
import logo from '../../img/Logo.png'
import { useFormik } from "formik";
import { Loginuser, api_url } from "../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { useRef } from "react";
import { EyeOff, EyeOn } from "../../img/Icon/SvgIcons";



const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Enter your Email";
  } else if (values.email.length < 1) {
    errors.email = "Must be 15 characters or less";
  }
  if (!values.password) {
    errors.password = "Enter Your password";
  } else if (values.password.length < 1) {
    errors.password = "Must be 15 characters or less";
  }

  return errors;
};
export default function DeleteAccount() {
  const [show, setShow] = useState(false)
  const iframeRef = useRef(null);
  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [EventIsOpen, setIsEventOpen] = React.useState(false);
  const location = useLocation();


  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      history("/Home");
    }
  }, [])


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      
      dispatch(Loginuser(values))
        .then((res) => {
         

          if (res.payload.success == true) {
              
            // let access_token = res?.payload?.token;
            // let type = res?.payload?.data?.name;
            // let userId = res?.payload?.data?.id;
            // let admin = res?.payload.data.is_admin;
            
            // if (access_token != "" && access_token != "") {
            //   localStorage.setItem("access_token", access_token);
            //   localStorage.setItem("author_type", type);
            //   localStorage.setItem("user_id", userId);
            //   localStorage.setItem("admin", admin);
             
              toast.success(res.payload.message);
              navigate("/login");
            
          } else {
            toast.error(res.payload.message);
           
          }
        })
        .catch((error) => {
        
        });
    },
  });
  function EventModal() {
    setIsEventOpen(true);
    
  }

  function EventcloseModal() {
    setIsEventOpen(false);
  }
  function EventOpenModal() { }

  const getIframeUrl = () => {
    if (iframeRef.current) {
      const iframeUrl = iframeRef.current.src;
      
    }
  };

  const handleIframeLoad = () => {
    try {
      const iframeElement = iframeRef.current;
      const iframeWindow = iframeElement.contentWindow.location.href;

      const iframeWindowUrl = iframeWindow;
     
    } catch (error) {
      
    }
  };



  const getnewitschange = () => {
    const datatokan = localStorage.getItem("access_DT");
    if (datatokan) {
      const requestBody = {
        its_token: datatokan
      };

      getloginchange(requestBody);
    } else {
      console.error("Access token not found in localStorage");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_DT")) {
      getnewitschange();
    }
  }, [])
  const getloginchange = (requestBody) => {
    
    fetch(api_url + 'api/admin/deleteAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (data?.status === true) {
          


          let access_token = data?.token;
          let type = data?.data?.first_name;
          let roletype = data?.data?.roles;
          if (access_token != "" && access_token != "") {
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("author_type", type);
            localStorage.setItem("access_Role", roletype);
            toast.success(data?.message);
            navigate("/Home");
          }
        } else {
          // toast.error(data?.message);
        }
      })
      .catch(error => {
        console.error(error);
      });

  }

  return (
    <div>
      <section className="Profile Deleted">
        <ToastContainer />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <div className="login_bg">
                <div className="log_in_page">
                 <div className="login_box">
                    <img src={logo} className="img-fluid logo-res" />
                  </div> 

                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">

              <form className={`Login_form ITS-Link d-none`} >
               
              </form>
                <form className="Login_form" onSubmit={formik.handleSubmit}>
                  <h1>Delete your account</h1>

                  <div className="form-group">
                    <label>
                      Email Id<span>*</span>
                    </label>
                    <input
                      name="email"
                      placeholder="Enter your Email ID"
                      autocomplete="off"
                      type="text"
                      className={`form-control ${formik.errors.email && formik.touched.email
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="error">{formik.errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Password<span>*</span>
                    </label>
                <span className="d-flex flex-row  justify-content-end align-items-center">
      <input
        name="password"
        placeholder="Enter Password"
        type={show ? 'text' : 'password'}
        autoComplete="off"
        className={`form-control d-flex flex-1 justify-content-end  form-control position-relative  ${formik.errors.password && formik.touched.password
          ? "is-invalid"
          : ""
          }`}
        
        value={formik.values.password}
        onChange={formik.handleChange}
      />
      <button type="button" onClick={() => setShow(!show)} className="btn position-absolute d-flex justify-content-end align-items-center 
">
        {show ? <EyeOn /> : <EyeOff />}
      </button>
    </span>
                    {formik.errors.password && formik.touched.password && (
                      <p className="error">{formik.errors.password}</p>
                    )}
                 
                    
                  </div>
             
                  {/* {storedURL} */}
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary mr-1 submit_btn"
                    >
                      Delete
                    </button>
                  </div>
                </form>
           
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={EventIsOpen}
        onAfterOpen={EventOpenModal}
        onRequestClose={EventcloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="Event_popup"
      >
        <div className="popup_modal dish-viwe">
          <div>
            <button onClick={EventcloseModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                  fill="#2E294E"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
