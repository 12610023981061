import react from 'react'

const Policy  = () => {
    return (
        <>
        <section className='Policy_section' >
        
            <div className="container">
            <h2 className='Policy_section1' >Privacy Policy of LaughMD</h2>
                <p>This document informs Users about the technologies that help this Application to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Application.

</p>
<p>
For simplicity, all such technologies are defined as "Trackers" within this document – unless there is a reason to differentiate.
</p>
<p>For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker.</p>
<p>Some of the purposes for which Trackers are used may also require the User's consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.</p>
<p>This Application uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them.</p> 
<p>The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session.</p>

<p>In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information — such as the presence of other Trackers — in the linked privacy policies of the respective third-party providers or by contacting the Owner.</p>

<h4>Activities strictly necessary for the operation of this Application and delivery of the Service</h4>

<p>This Application uses so-called “technical” Cookies and other similar Trackers to carry out activities that are strictly necessary for the operation or delivery of the Service.</p>

<h4>Other activities involving the use of Trackers</h4>
<h3>Experience</h3>

<p>This Application uses Trackers to improve the quality of the user experience and enable interactions with external content, networks and platforms.</p>

<h3>Marketing</h3>

<p>Marketing
This Application uses Trackers to deliver personalized marketing content based on User behavior and to operate, serve and track ads.</p>


<h4>How to manage preferences and provide or withdraw consent</h4>
<p>There are various ways to manage Tracker related preferences and to provide and withdraw consent, where relevant:</p>

<p>Users can manage preferences related to Trackers from directly within their own device settings, for example, by preventing the use or storage of Trackers.</p>

<p>Additionally, whenever the use of Trackers is based on consent, Users can provide or withdraw such consent by setting their preferences within the cookie notice or by updating such preferences accordingly via the relevant consent-preferences privacy widget, if available.</p>

<p>It is also possible, via relevant browser or device features, to delete previously stored Trackers, including those used to remember the User’s initial consent preferences.</p>

<p>With regard to any third-party Trackers, Users can manage their preferences via the related opt-out link (where provided), by using the means indicated in the third party's privacy policy, or by contacting the third party.</p>

<h6>How to opt out of interest-based advertising
</h6>
<p>Notwithstanding the above, Users may follow the instructions provided by YourOnlineChoices (EU and UK), the Network Advertising Initiative (US) and the Digital Advertising Alliance (US), DAAC (Canada), DDAI (Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.</p>

<h6>Consequences of denying the use of Trackers
</h6>

<p>Users are free to decide whether or not to allow the use of Trackers. However, please note that Trackers help this Application to provide a better experience and advanced functionalities to Users (in line with the purposes outlined in this document). Therefore, if the User chooses to block the use of Trackers, the Owner may be unable to provide related features.</p>

<h3>Owner and Data Controller</h3>
<h4>LaughMD</h4>

<p>Since the use of third-party Trackers through this Application cannot be fully controlled by the Owner, any specific references to third-party Trackers are to be considered indicative. In order to obtain complete information, Users are kindly requested to consult the privacy policies of the respective third-party services listed in this document.</p>


<p>Given the objective complexity surrounding tracking technologies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of such technologies by this Application.</p>







            </div>
        </section>
        </>
         
    )
}

export default Policy