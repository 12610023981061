import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Home/Home.css";
import Slider from "react-slick";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Formik and YUP 
import { Form , Formik ,Field , ErrorMessage } from "formik";
import * as Yup from 'yup'

var Products = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,

  // responsive: [
  //   {
  //     breakpoint: 1024, // Adjust breakpoint as needed
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 3,
  //     },
  //   },
  //   {
  //     breakpoint: 768, // Adjust breakpoint as needed
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 480, // Adjust breakpoint as needed
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //   },
  // ],
};

export default function HomePage() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling animation
    });
  };
 
  const intialValue = {
    name: '',
    email: '',
    number: '',
    message: ''
  }
  const validation = Yup.object({
    name: Yup.string().required('Name is required') .matches(/^[a-zA-Z\s]+$/, 'Name must contain only alphabets '),
    email: Yup.string().required('Email is required').email('Invalid email address'),
    number: Yup.string()
      .required('Number is required')
      .matches(/^[0-9]+$/, 'Number must contain only digits'),
    message: Yup.string().required('Message is required'),
  });
  

  const handleSendEmail = async (values , {resetForm}) => {
   console.log(values)
   
   try {
    const response = await fetch('https://laughmd.app:5000/support', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });

 

    // Parse the response JSON
    const responseData = await response.json();
console.log(responseData,"responseData");
    // Check if the response from the server indicates success
    if (responseData.status == true) {
      // Show success toast
      toast.success(responseData.message);
      // Optionally, you can reset the form
    resetForm();
    } else {
      // Show error toast
      toast.error(responseData.message);
    }
  } catch (error) {
    // Show error toast
    toast.error('There was a problem sending the message');
    console.error('There was a problem sending the message:', error.message);
  }
  }

 


  return (
    <>
      <header className="header_home">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">

              <Link to="/">
                <img
                  src="images/logo.png"
                  alt="header-logo"
                  className="img-fluid brand_logo1"
                  onClick={() => window.location.reload()}
                />
              </Link>
            </div>
            <div className="col-6 text-end">
              <div className="navbar-app-btns1 ">
                <ul className="list-inline text-white">
                  <li className="list-inline-item">
                    <Link to="/login" className="login_btn">
                      Login
                    </Link>
                  </li>
                  {/* <li className="list-inline-item d-none d-md-block">
                    <p>Download App</p>
                  </li> */}

                  <li className="list-inline-item">
                    <a href="https://apps.apple.com/in/app/laughmd/id6478386703" target="_blank">
                      <img
                        src="images/apple-white-icon.png"
                        alt=""
                        title="App Store"
                      />
                    </a>
                  </li>

                  <li className="list-inline-item">
                    <a href="https://play.google.com/store/apps/details?id=com.laughmd&pcampaignid=web_share" target="_blank">
                      <img
                        src="images/play-store-white-icon.png"
                        alt=""
                        title="Google Play"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="hero-banner1 section1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 text-center order-md-2 mb-4 mb-md-0">
              <div className="hero-banner-inner-image1">
                <img
                  src="images/logo-device.png"
                  className="img-fluid"
                  alt=""
                  width="300"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="hero-banner-inner1">
                <h1>
                  <span>Welcome to</span>
                  <b>LaughMD</b> App
                </h1>

                <h5>
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis sed do eiusmod tempor
                  incididunt.{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-live-main1 section1">
        <div className="container">
          <div className="row align-items-center justify-content-md-center">
            <div className="col-lg-5 col-md-6 text-center">
              <img
                src="images/front-laugh.png"
                className="img-fluid"
                alt=""
                width="300"
              />
            </div>

            <div className="col-lg-7 col-md-12 mt-lg-0 mt-5">
              <div className="about-content1">
                <h2>About LaughMD</h2>
                <h5>
                  If "Laughter is the Best Medicine," why not try it as
                  medicine?”{" "}
                </h5>
                <p>
                  I’m Prof. Frank Chindamo, and I created LaughMD. It’s an app
                  that delivers comedy videos to patients and providers, while
                  also querying for healing data. We are a digital therapeutic
                  company solving for pain and stress.
                </p>
                <p>
                  Over 405 different scientific studies show the health benefits
                  of laughter. LaughMD’s own 4 studies show that the LaughMD app
                  has lowered pain in 91% of patients with pain at USC’s Norris
                  Cancer Hospital. At USC’s Institute for Genetic Medicine, they
                  reduced provider stress by 94%. And the US and CA Surgeon
                  Generals have applauded their work. There’s a 2-minute
                  explainer video at{" "}
                  <a href="https://www.laughmd.com">
                    <b>https://www.laughmd.com</b>
                  </a>{" "}
                  Please try it, you have nothing to lose by laughing, and
                  unlike prescription medication, there are no side effects.
                  Unless you have a broken rib. Then just smile
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="stream-main1 section1">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-6 text-center order-md-2 mb-4 mb-md-0">
              <img
                src="images/video-call.png"
                className="img-fluid live-image"
                alt=""
                width="300"
              />
            </div>

            <div className="col-lg-5 col-md-6">
              <div className="stream-content1">
                <h2>
                  <span>Live Expression</span>Capturing...
                </h2>
                <p>
                  Donec sit amet ante at nunc pretium mattis. Lorem ipsum dolor
                  sit amet, Lorem ipsum dolor sit nam sed ligula odio amet ante
                  Sed id metus felis.
                </p>

                <h5>Download App and get started</h5>

                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href="https://apps.apple.com/in/app/laughmd/id6478386703" target="_blank">
                      <img
                        src="images/Appstore.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://play.google.com/store/apps/details?id=com.laughmd&pcampaignid=web_share" target="_blank">
                      <img
                        src="images/googleplay.png"
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-main1 section1">
        <div className="container">
          <div className="row justify-content-between align-items-center align-items-lg-start">
            <div className="col-lg-5 col-md-6">
              <div className="testimonial-content1">
                <h5>Our Testimonial</h5>
                <h2>What Our User’s Say</h2>
                <img
                  src="images/quote.png"
                  alt=""
                  className="img-fluid mb-lg-5 mb-3"
                />
              </div>

              <div className="testimonial-slider1">
                <div className="slider recent-project-slider1">
                  <Slider {...Products}>
                    <div>
                      <div className="testimonial-box1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis.{" "}
                        </p>

                        <h6>John Watson</h6>
                      </div>
                    </div>
                    <div>
                      <div className="testimonial-box1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis.{" "}
                        </p>

                        <h6>John Watson</h6>
                      </div>
                    </div>
                    <div>
                      <div className="testimonial-box1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis.{" "}
                        </p>

                        <h6>John Watson</h6>
                      </div>
                    </div>
                    <div>
                      <div className="testimonial-box1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis.{" "}
                        </p>

                        <h6>John Watson</h6>
                      </div>
                    </div>
                    <div>
                      <div className="testimonial-box1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis.{" "}
                        </p>

                        <h6>John Watson</h6>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-sm-0 mt-5">
              <img src="images/testmonial.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="app-download-main1 pb-0 section1">
        <div className="container">
          <div className="app-download-heading1 text-center text-white">
            <h2>Want to try it out?</h2>
            <h5>App is Available on these platform</h5>
          </div>

          <div className="app-download-inner1">
            <div className="app-download-inner-content1">
              <h4>IOS</h4>
              <a href="https://apps.apple.com/in/app/laughmd/id6478386703" target="_blank">Download</a>
              <img src="images/ios-device.png" alt="" className="img-fluid" />
            </div>

            <div className="app-download-inner-content1">
              <h4>Android</h4>
              <a href="https://play.google.com/store/apps/details?id=com.laughmd&pcampaignid=web_share" target="_blank">Download</a>

              <img
                src="images/android-device.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="get-touch-main1 section1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 px-lg-5">
              <img src="images/dog.jpg" alt="" className="img-fluid" />
            </div>

            <div className="col-lg-6 col-md-6 px-lg-5 mt-md-0 mt-4">
      <div className="get-touch-box1">
        <h2>Get In Touch</h2>
        <p>Through this form send us message if you have another query.</p>
      
          <div className="row">
          <Formik initialValues={intialValue} validationSchema={validation} onSubmit={handleSendEmail}>
  {({ handleSubmit }) => (
    <Form onSubmit={handleSubmit}>
    <div className="col-md-12">
      <div className="mb-3">
        <Field
          type="text"
          className="form-control"
          placeholder="Name"
          name="name"
        />
        <ErrorMessage component={'div'} className="text-danger" name="name" />
      </div>
    </div>

    <div className="col-md-12">
      <div className="mb-3">
        <Field
          type="email"
          className="form-control"
          placeholder="Email"
          name="email"
        />
        <ErrorMessage component={'div'} className="text-danger" name='email' />
      </div>
    </div>

    <div className="col-md-12">
      <div className="mb-3">
        <Field
          type="text"
          className="form-control"
          placeholder="Phone number"
          name="number"
        />
        <ErrorMessage component={'div'} className="text-danger" name='number' />
      </div>
    </div>

    <div className="col-md-12">
      <div className="mb-3">
        <Field as='textarea'
          className="form-control"
          placeholder="Write your message here"
          rows="5"
          id="request"
          name="message"
        ></Field>
        <ErrorMessage component={'div'} className="text-danger" name='message' />
      </div>
    </div>

    <div className="col-md-12 text-center">
      <button type="submit"  className="btn-block dark-btn1 w-100">
        Send Message
      </button>
    </div>
    </Form>
  )}
</Formik>

          </div>
       
      </div>
    </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="footer-logo1">
                <img
                  src="images/logo.png"
                  alt=""
                  className="img-fluid"
                  onClick={scrollToTop} // Call scrollToTop function on click
                  style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickability
                />
              </div>
            </div>

            {/* <div className="col-md-4">
              <ul className="list-inline social_icon1 text-center">
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa fa-google"></i>
                  </a>
                </li>
              </ul>
            </div> */}

            <div className="col-md-6">
              <div className="copyright-text1 text-md-right text-lg-end">
                <p>
                  © 2024 LaughMD. All rights reserved.{" "}
                  <Link to="/privacy-policy" className="privacypolicy">
                    Privacy-Policy
                  </Link>
                </p>

                {/* <Link to="/login" className="login_btn">
                        </Link> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
