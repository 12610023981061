import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// const api_url = "https://nizr0tz0n8.execute-api.us-east-1.amazonaws.com/";
// export const api_url = "http://44.208.64.169:5000/";
export const api_url = "https://laughmd.app:5000/";
// export const api_url = "http://laughmd.codingserver.com:5000/";
var token = localStorage.getItem("access_token");
var userId = localStorage.getItem("user_id");
var admin = localStorage.getItem("admin");
const initialState = {
  token: "",
  loading: false,
  error: "",
  user: "",
  msg: "",
  email: "",
  password: "",
  first_name: "",
  lastName: "",
  age: "",
  sex: "",
  nation: "",
  isLoading: false,
  logo: "",
  package_type: "",
  packaging_size: "",
  recommended_storage: "",
  unit: "",
  updated_at: "",
  vender: '',
  UpdatethaliStatus: 'idle',
};
export const Loginuser = createAsyncThunk("loginuser", async (values) => {
  
  return await fetch(api_url + "api/admin/signin", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `${token}`,
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});


export const getcategorylist = createAsyncThunk("get_category_list", async (payload) => {
  const { search, currentPage, showData, agencyId } = payload;
  const userIdOrAgencyId = agencyId ? agencyId : userId;

  return await fetch(api_url + `category/categoryListForAdmin?user_id=${userIdOrAgencyId}&search=${search}&page=${currentPage}&show=${showData}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  })
})
export const getDashboardMenu = createAsyncThunk("get_dashboard_menu", async () => {

  return await fetch(api_url + `dashboard?user_id=${userId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  })
})
export const getCategoryMenulist = createAsyncThunk("get_category_menu_list", async (payload) => {
  const { categoryPage, agencyMenuId } = payload;
  const userIdOrAgencyId = agencyMenuId ? agencyMenuId : userId;
  return await fetch(api_url + `category/categoryListForAdmin?user_id=${userIdOrAgencyId}&page=${categoryPage}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  })
})




export const deletecategorylist = createAsyncThunk('deletecategorylist', async (category_id) => {
  const requestOptions = {
    category_id: `${category_id}`
  };
  return await fetch(api_url + `category/deleteCategory?category_id=${category_id}&user_id=${userId}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})

export const deleteUser = createAsyncThunk('deleteUsers', async (user_id) => {
  const requestOptions = {
    user_id: `${user_id}`
  };
  return await fetch(api_url + `user/deleteUsers?user_id=${user_id}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})

export const deleteAgencyData = createAsyncThunk('deleteAgencyData', async (id) => {
  const requestOptions = {
    id: `${id}`
  };
  return await fetch(api_url + `agency/deleteAgency?agency_id=${id}&user_id=${userId}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})
export const deleteOption = createAsyncThunk('deleteAnswer', async (id) => {
  const requestOptions = {
    id: `${id}`
  };
  return await fetch(api_url + `feedback/deleteFeedbackOption?feedback_option_id=${id}&user_id=${userId}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})
export const deleteVideoData = createAsyncThunk('deleteVideoData', async (id) => {
  const requestOptions = {
    id: `${id}`
  };
  return await fetch(api_url + `video/deleteVideo?id=${id}&user_id=${userId}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})



export const deleteVideoViewOfUser = createAsyncThunk('deleteVideoViewOfUser', async (id, user_id) => {
  
  const requestOptions = {
    id: `${id}`
  };
  return await fetch(api_url + `video/deleteVideoViewOfUser?id=${id}&users_id=${user_id}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})
export const deleteFeedbackQuestion = createAsyncThunk('deleteFeedbackQuestion', async (id) => {
  const requestOptions = {
    id: `${id}`
  };
  return await fetch(api_url + `feedback/deleteFeedbackQuationAndOptions?feedback_question_id=${id}&user_id=${userId}&is_admin=${admin}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `jwt ${token}`,
    },

    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})



export const getsubcategorylist = createAsyncThunk("get_subcategory_list", async (payload) => {
  const { category_id, search, currentPage, showData, agencyId } = payload;
  const userIdOrAgencyId = agencyId ? agencyId : userId;
  return await fetch(api_url + `category/subCategoryListForAdmin?categoryId=${category_id}&search=${search}&page=${currentPage}&show=${showData}&user_id=${userIdOrAgencyId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});
export const getsubcategoryMenuList = createAsyncThunk("get_subcategory_menu_list", async (payload) => {
  const { category_id, categoryPage } = payload;
  return await fetch(api_url + `category/subCategoryListForAdmin?categoryId=${category_id}&page=${categoryPage}&user_id=${userId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});
export const emotionByVideo = createAsyncThunk("emotion_by_video", async (payload) => {
  const { data, search, currentPage } = payload;
  return await fetch(api_url + `user/getEmotionOnVideoOfUser?user_id=${data}&search=${search}&page=${currentPage}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});
export const emotionVideo = createAsyncThunk("emotion_on_video", async (payload) => {
  const { id, currentPage, showData, data } = payload;
  return await fetch(api_url + `user/getAllEmotionOnVideoOfUser?user_id=${data}&video_id=${id}&page=${currentPage}&show=${showData}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});
export const getUserlist = createAsyncThunk("get_user_list", async (payload) => {
  const { search, currentPage, showData, agencyId } = payload;
  const userIdOrAgencyId = agencyId ? agencyId : userId;
  return await fetch(api_url + `user/getUsersList?search=${search}&page=${currentPage}&show=${showData}&user_id=${userIdOrAgencyId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});


export const getVideoList = createAsyncThunk("get_video_list", async (payload) => {
  const { search, currentPage, showData, agencyId } = payload;
  const userIdOrAgencyId = agencyId ? agencyId : userId;
  return await fetch(api_url + `video/getVideoList?page=${currentPage}&search=${search}&show=${showData}&user_id=${userIdOrAgencyId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});
export const getAgencyList = createAsyncThunk("get_agency_list", async (payload) => {
  const { search, currentPage, showData } = payload;
  return await fetch(api_url + `agency/getAgencyList?search=${search}&page=${currentPage}&show=${showData}&user_id=${userId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  })
});
export const getAgencyMenuList = createAsyncThunk("get_agency_Menulist", async (payload) => {
  const { agencyPage, agencyId } = payload;
  return await fetch(api_url + `agency/getAgencyList?page=${agencyPage}&agency_id=${agencyId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  })
});
export const getAgencyPopupMenuList = createAsyncThunk("get_agency_Menulist", async (payload) => {
  const { agencyMenuPage, agencyMenuId } = payload;
  return await fetch(api_url + `agency/getAgencyList?page=${agencyMenuPage}&user_id=${agencyMenuId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  })
});
export const getQAList = createAsyncThunk("get_QA_list", async (payload) => {
  const { search, currentPage, showData, agencyId } = payload;
  const userIdOrAgencyId = agencyId ? agencyId : userId;
  return await fetch(api_url + `feedback/getFeedbackQuationList?search=${search}&page=${currentPage}&show=${showData}&user_id=${userIdOrAgencyId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
})
export const editGetFeedbackQuestion = createAsyncThunk("Edit_Feedback_Question", async (questionId) => {
  // const { questionId } = payload;
  return await fetch(api_url + `feedback/viewFeedbackQuationAndOption?feedback_question_id=${questionId}&user_id=${userId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
})




export const createCalender = createAsyncThunk("createCalender", async (values) => {
  try {
    const response = await fetch(api_url + "admin/thali/create", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      const data = await response.json();
      
      // Perform any necessary actions with the response data
      toast.success(data.message);
      return data;
    } else {
      const errorData = await response.json();
      toast.error(errorData.message);
      throw new Error(errorData.message);
    }
  } catch (error) {
    console.error("API error:", error);
    toast.error("An error occurred while creating the Thali");
    throw error;
  }
});



export const createRegisteruse = createAsyncThunk("createRegisteruse", async (values) => {
  return await fetch(api_url + "admin/update/user-thaali", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(values)
  }).then((res) => {
    if (res.status == 200) {
      toast.success("Thali  successfully Update");
     
      return res.json();
    }
    else {
      toast.error(res.data.message);
    }
  });
});


export const signIn = createAsyncThunk("signin", async (values) => {
  return await fetch(api_url + "admin/registration", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `${token}`,
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});

export const Updatethali = createAsyncThunk('thali/update', async ({ id, values }) => {
  try {
    const response = await fetch(api_url + `admin/thali/update/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: `${token}`,
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw new Error(error.message);
  }
});







export const getAllVendor = createAsyncThunk("getAllVendor", async () => {
  return await fetch(api_url + "admin/vendor-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});

export const getAllingredients = createAsyncThunk("getAllingredients", async () => {
  return await fetch(api_url + "admin/ingredients-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});


export const getAllcaterer = createAsyncThunk("getAllcaterer", async () => {
  return await fetch(api_url + "admin/caterer-get", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});

export const getDishType = createAsyncThunk("get_DishType", async () => {
  return await fetch(api_url + "admin/type/dish", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});


export const getlabelView = createAsyncThunk('getlabelView', async (LABELVIEW) => {
  const requestOptions = {
    LABELVIEW: `${LABELVIEW}`
  };
  return await fetch(api_url + `admin/label-template-users?date=${LABELVIEW}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
    },
    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})


export const getzoneView = createAsyncThunk('getzoneView', async (ZONEVIEW) => {
  const requestOptions = {
    ZONEVIEW: `${ZONEVIEW}`
  };
  return await fetch(api_url + `admin/zone-sheet-users?date=${ZONEVIEW}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
    },
    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})





export const Reportlist = createAsyncThunk("Reportlist", async () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const todayDate = `${year}-${month}-${day}`;


  const startDate = new Date(today);
  startDate.setDate(startDate.getDate() - today.getDay());

  const endDate = new Date(today);
  endDate.setDate(endDate.getDate() + (6 - today.getDay()));

  const startYear = startDate.getFullYear();
  const startMonth = String(startDate.getMonth() + 1).padStart(2, '0');
  const startDay = String(startDate.getDate()).padStart(2, '0');
  const endYear = endDate.getFullYear();
  const endMonth = String(endDate.getMonth() + 1).padStart(2, '0');
  const endDay = String(endDate.getDate()).padStart(2, '0');

  const startOfWeek = `${startYear}-${startMonth}-${startDay}`;
  const endOfWeek = `${endYear}-${endMonth}-${endDay}`;

  const requestPayload = {
    start_date: startOfWeek,
    end_date: endOfWeek
  };

  return await fetch(api_url + "admin/user-report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(requestPayload)
  }).then((res) => {
    return res.json();
  });
});



export const createVendor = createAsyncThunk("create_vendor", async (values) => {
  return await fetch(api_url + "admin/vendor/Create", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(values)
  }).then((res) => {
    return res.json();
  });
});
export const updateVendor = createAsyncThunk("update_vendor", async (values) => {
  return await fetch(api_url + "admin/vendor/update", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(values)
  }).then((res) => {
    return res.json();
  });
});
export const getThaliList = createAsyncThunk("getThaliList", async () => {
  return await fetch(api_url + "admin/dish/list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});


export const getThalilist = createAsyncThunk("getThali_List", async () => {
  return await fetch(api_url + "admin/thali-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});


export const createcaterer = createAsyncThunk("create_caterer", async (values) => {
  return await fetch(api_url + "admin/caterer-create", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(values)
  }).then((res) => {
    return res.json();
  });
});

export const pickuptypeuser = createAsyncThunk("pickuptypeuser", async (values) => {
  return await fetch(api_url + "admin/update/pickup-type", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(values)
  }).then((res) => {
    return res.json();
  });
});



export const updateDish = createAsyncThunk("update_dish", async (values) => {
  return await fetch(api_url + "admin/dish/update", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(values)
  }).then((res) => {
    return res.json();
  });
});

export const deletethali = createAsyncThunk('deletethali', async (thali_id) => {
  const requestOptions = {
    thali_id: `${thali_id}`
  };
  return await fetch(api_url + `admin/deleteThaaliSchedule/${thali_id}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
    },
    body: JSON.stringify()

  }).then(res => {
    return res.json()
  });
})



export const Thalischedule = createAsyncThunk("getThalischedule", async () => {
  return await fetch(api_url + "admin/schedule-list-detail", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});

export const getRegisterdatalist = createAsyncThunk("getRegisterdatalist", async () => {
  return await fetch(api_url + "admin/registered-users", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});
export const authReducer = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Loginuser.pending, (state) => {
      return { ...state, Loginuser: "pending" };
    });
    builder.addCase(Loginuser.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(Loginuser.rejected, (state) => {
      return { ...state, Loginuser: "failed" };
    });



    builder.addCase(signIn.pending, (state) => {
      return { ...state, signin: "pending" };
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(signIn.rejected, (state) => {
      return { ...state, signin: "failed" };
    });



    builder.addCase(createCalender.pending, (state) => {
      return { ...state, createCalender: "pending" };
    });
    builder.addCase(createCalender.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createCalender.rejected, (state) => {
      return { ...state, createCalender: "failed" };
    });




    builder
      .addCase(Updatethali.pending, (state) => {
        state.UpdatethaliStatus = 'pending';
      })
      .addCase(Updatethali.fulfilled, (state, action) => {
        state.UpdatethaliStatus = 'fulfilled';
        // Handle the action.payload data if needed
      })
      .addCase(Updatethali.rejected, (state) => {
        state.UpdatethaliStatus = 'rejected';
      });


    builder.addCase(createRegisteruse.pending, (state) => {
      return { ...state, createRegisteruse: "pending" };
    });
    builder.addCase(createRegisteruse.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createRegisteruse.rejected, (state) => {
      return { ...state, createRegisteruse: "failed" };
    });


    builder.addCase(createVendor.pending, (state) => {
      return { ...state, createVendor: "pending" };
    });
    builder.addCase(createVendor.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createVendor.rejected, (state) => {
      return { ...state, createVendor: "failed" };
    });


    builder.addCase(updateVendor.pending, (state) => {
      return { ...state, updateVendor: "pending" };
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(updateVendor.rejected, (state) => {
      return { ...state, updateVendor: "failed" };
    });

    builder.addCase(createcaterer.pending, (state) => {
      return { ...state, createcaterer: "pending" };
    });
    builder.addCase(createcaterer.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createcaterer.rejected, (state) => {
      return { ...state, createcaterer: "failed" };
    });

    builder.addCase(updateDish.pending, (state) => {
      return { ...state, updateDish: "pending" };
    });
    builder.addCase(updateDish.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(updateDish.rejected, (state) => {
      return { ...state, updateDish: "failed" };
    });
  },

});


export const getYoutubeVideoDetail = createAsyncThunk("get_video_list", async (payload) => {
  const { video_id } = payload;
  return await fetch(api_url + `video/getYoutubeVideoDetails?video_id=${video_id}&user_id=${userId}&is_admin=${admin}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
});

export default authReducer.reducer;
