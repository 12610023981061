import React, { useState, useEffect } from "react";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import "../Dashboard.css";
import remove from "../../../img/remove.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import validator from 'validator'
import {

  deleteAgencyData,
  getAgencyList,
  api_url
} from "../../../Redux/Reducer/AuthReducer";
import axios from "axios";
import ResponsivePagination from 'react-responsive-pagination';
import Select from "react-select";
import loadergif from '../../../img/landing/loaderBlue.gif';

export default function Agency() {
  const dispatch = useDispatch();
  const [agencyData, setAgencyData] = useState([]);
  const [agencyEmail, setAgencyEmail] = useState(null);
  const [codeAppliedOn, setCodeAppliedOn] = useState("");
  const [search, setSearch] = useState('');
  const [mobileNumber, setMobileNumber] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [validationerror, setValidationerror] = useState(false)
  const [totalcount, setTotalCount] = useState('');
  const [error, setError] = useState(null)
  const [isSuccess, setIsSuccess] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [paginationPage, setPaginationPage] = useState(1)
  const [showData, setShowData] = useState(10)
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  var userId = localStorage.getItem("user_id");
  var admin = localStorage.getItem("admin");

  const validateEmail = (e) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  const getAgencyListData = () => {
    setLoading(true)
    dispatch(getAgencyList({ search, currentPage, showData })).then((res) => {
      if (res?.payload?.success == true) {
        setLoading(false)
        setIsSuccess(res?.payload?.success)
        setAgencyData(res?.payload?.data);
        
        setTotalCount(res?.payload?.count)
      } else {
        setLoading(false)
        setIsSuccess(res?.payload?.success)
        setError(res?.payload?.message)
        setTotalCount(0)
      }
    });
  };

  const deleteAgency = () => {
    dispatch(deleteAgencyData(agencyId)).then((res) => {
      if (res?.payload?.success == true) {
        toast.success(res?.payload?.message);
        RemovecloseModal();
        getAgencyListData();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };







  const reset = () => {
    setAgencyName('')
    setMobileNumber('')
    setAgencyEmail('')
    setCodeAppliedOn('')
    setAgencyId('')
    setValidationerror(false)
    setPassword("")
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (!agencyName || !agencyEmail || !mobileNumber || !codeAppliedOn || codeAppliedOn === "0" || !password || agencyName.trim() === '' || agencyEmail.trim() === '' || mobileNumber.trim() === '' || codeAppliedOn.trim() === '' || emailError == true || password.trim() === ''
    ) {
      setValidationerror(true)
      return false
    }
    else {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          'Authorization': `jwt ${token}`
        }
      };
      const postData = {
        agency_name: agencyName,
        mobile_number: mobileNumber,
        email: agencyEmail,
        code_applied_on: codeAppliedOn,
        password: password
      };
      axios
        .post(api_url + `agency/addAgency`, postData, config)
        .then((res) => {
          
          if (res?.data.success == true) {
            toast.success(res?.data?.message);

            const modal_close = document.getElementById("close_btn").click();
            setValidationerror(false)
            getAgencyListData();
          } else {
            toast.error(res?.data?.message);
          }
        });

    };
  }



  const handleupdateSubmit = (e) => {
    e.preventDefault();


    if (!agencyName || !agencyEmail || !mobileNumber || !codeAppliedOn || codeAppliedOn === "0" || agencyName.trim() === '' || agencyEmail.trim() === '' || mobileNumber.trim() === '' || emailError == true
    ) {
      setValidationerror(true)
      return false
    }
    else {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          'Authorization': `jwt ${token}`
        }
      };
      const postData = {
        agency_name: agencyName,
        mobile_number: mobileNumber,
        code_applied_on: codeAppliedOn,
        agency_id: agencyId,
        email: agencyEmail
      };
      axios
        .post(api_url + `agency/editAgency`, postData, config)
        .then((res) => {
          if (res?.data.success == true) {
            toast.success(res?.data.message);
            const modal_close2 = document.getElementById("close_btn2").click();
            setValidationerror(false)
            reset()
            getAgencyListData();

          } else {
            toast.error(res?.data?.message)

          }
        });

    };
  }


  const handleCancel = () => {
    setSearch('');
  };

  const dataget = (item) => {
    
    
    setAgencyEmail(item?.email);
    setAgencyName(item?.agency_name);
    setCodeAppliedOn(item?.code_applied_on)
    setMobileNumber(item?.mobile_number)
    setAgencyId(item?.id)

  };

  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getcategoryDataalllist = totalcount ? totalcount : "";



  useEffect(() => {
    getAgencyListData();
  }, [search, currentPage, totalcount, showData]);
  const totalPages = Math.ceil(totalcount / showData);
  const handlePageChange = (page) => {
    setCurrentPage(page - 1)
    setPaginationPage(page)
  }
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (parseInt(selectedValue) == 10) {
      setShowData(parseInt(selectedValue));
    } else if (parseInt(selectedValue) == 20 && totalcount < 20) {
      setShowData(parseInt(selectedValue));
      setCurrentPage(0)
      setPaginationPage(1)
    } else if (parseInt(selectedValue) == 50 && totalcount < 50) {
      setShowData(parseInt(selectedValue));
      setCurrentPage(0)
      setPaginationPage(1)
    } else {
      setShowData(parseInt(selectedValue));
    }
  };
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      {" "}
                      <h1 className="title_heading">
                        Agency code{" "}{getcategoryDataalllist ? "(" + getcategoryDataalllist + ")" : ''}
                      </h1>{" "}
                    </div>

                    <div className="Thali_page_btn">
                      <div className="New_section">

                        <button
                          className="btn thali_btn"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          onClick={reset}
                        >
                          Add new agency code 
                        </button>
                        &nbsp; &nbsp;
                        &nbsp;{" "}
                      </div>
                      &nbsp;
                    </div>
                  </div>
                </div>
                <div className="mb-3 menu-search-container">
                  <div className="dropdown mx-3 mb-3" >
                    <label for='number-dd'>Show</label>
                    <div>
                      <select onChange={handleSelectChange} className="select-number" name='number'>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                      </select>
                    </div>
                  </div>
                  <div className="mx-3 mb-3 search-input">
                    <div className="search-container">
                      <label for='number-dd'>Search</label>

                      <div className="thali_count_list " >
                        <div class="form-group has-search">
                          <span className="fa fa-search form-control-feedback"></span>
                          {/* class="form-control" */}
                          <input type="search"   className="form-control blurred-placeholder"  id="floatingInput" placeholder="Search agency name or agency email or agency code or agency mobile number" onChange={(e) => setSearch(e.target.value)} value={search} />                         
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mx-3 mb-3 dropdown">

                    <label for="number-dd">
                      Agency list
                    </label>

                    <Select
                      placeholder="Select agency "
                      className="w-100"
                    />

                  </div> */}
                </div>

                <div className="thali_table_section">
                  {loading ? (

                    <div className='Loading-img'><img src={loadergif} className='' /></div>

                  ) : (
                    <div></div>
                  )
                  }
                   <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" >
                          <span className="thali_table"> Agency name   &nbsp; &nbsp;
                          </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Agency email </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Mobile number</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Agency code</span>
                        </th>

                        <th scope="col">
                          <span className="thali_table">Code limit</span>
                        </th>
                        {/* used_code_count */}
                        <th scope="col">
                          <span className="thali_table">Used agency code</span>
                        </th>

                        <th scope="col">
                          <span className="thali_table">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isSuccess === true ?
                          (agencyData && (
                            <>
                              {agencyData?.map((item) => {


                                return (
                                  <tr key={item?.id}>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.agency_name ? item?.agency_name : "-"}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.email ? item?.email : "-"}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.mobile_number ? item?.mobile_number : "-"}
                                        </li>
                                      </ul>
                                    </th>  <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.code ? item?.code : "-"}
                                        </li>
                                      </ul>
                                    </th>
                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.code_applied_on ? item?.code_applied_on : "-"}
                                        </li>
                                      </ul>
                                    </th>

                                    {/* used_code_count */}
                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.used_code_count ? item?.used_code_count : "-"}
                                        </li>
                                      </ul>
                                    </th>
                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          <button className="borderless-button" onClick={() => dataget(item)} >
                                            <a

                                              class="dropdown-item"
                                              data-bs-toggle="offcanvas"
                                              data-bs-target="#offcanvasRightEdit"
                                              aria-controls="offcanvasRightEdit"
                                            >
                                              <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#004874" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#004874" />
                                              </svg>
                                            </a>
                                          </button>

                                          <button onClick={() => {
                                            RemoveModal()
                                            dataget(item)
                                          }} className="borderless-button">

                                            <svg width="64px" height="64px" viewBox="-184.32 -184.32 1392.64 1392.64" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#" stroke="#" stroke-width="0.01024">
                                              <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                              <g id="SVGRepo_iconCarrier">
                                                <path d="M960 160h-291.2a160 160 0 0 0-313.6 0H64a32 32 0 0 0 0 64h896a32 32 0 0 0 0-64zM512 96a96 96 0 0 1 90.24 64h-180.48A96 96 0 0 1 512 96zM844.16 290.56a32 32 0 0 0-34.88 6.72A32 32 0 0 0 800 320a32 32 0 1 0 64 0 33.6 33.6 0 0 0-9.28-22.72 32 32 0 0 0-10.56-6.72zM832 416a32 32 0 0 0-32 32v96a32 32 0 0 0 64 0v-96a32 32 0 0 0-32-32zM832 640a32 32 0 0 0-32 32v224a32 32 0 0 1-32 32H256a32 32 0 0 1-32-32V320a32 32 0 0 0-64 0v576a96 96 0 0 0 96 96h512a96 96 0 0 0 96-96v-224a32 32 0 0 0-32-32z" fill="#EB5548" />
                                                <path d="M384 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM544 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM704 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0z" fill="#EB5548" />
                                              </g>
                                            </svg>
                                          </button>

                                        </li>

                                      </ul>
                                    </th>

                                  </tr>
                                );
                              })}
                            </>
                          ))
                          :
                          search != '' ? (
                            <tr >

                              <td colSpan="6" >
                                <ul className="NotFound text-center">
                                  <li>
                                    {error}
                                  </li>
                                </ul>


                              </td>

                            </tr>
                          )
                            : null
                      }


                    </tbody>
                  </table>
                  </div>
                  {/* Pagination */}
                  {
                    (totalcount > 10 && showData === 10) ||
                      (totalcount > 20 && showData === 20) ||
                      (totalcount > 50 && showData === 50)
                      ? (
                        <ResponsivePagination
                          total={totalPages}
                          current={paginationPage}
                          onPageChange={page => handlePageChange(page)}
                        />
                      )
                      : null
                  }
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>


      {/* Add Agency Code Popup */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas   "
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Add agency code
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Agency name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter agency name "
                    className={`${validationerror && !agencyName || validationerror && agencyName.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setAgencyName(e.target.value)}
                    value={agencyName}
                  />
                  {
                    validationerror && !agencyName ?
                      (<p className="show_invalid_text" >Agency name is required</p>)
                      :
                      (
                        validationerror && agencyName.trim() === '' &&
                        (<p className="show_invalid_text" >Agency name must include chracter </p>)

                      )
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Mobile number
                  </label>
                  <input
                    type="number"
                    placeholder="Enter mobile number "
                    className={`${validationerror && !mobileNumber ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setMobileNumber(e.target.value)}
                    value={mobileNumber}
                  />
                  {
                    validationerror && !mobileNumber &&
                    <p className="show_invalid_text" > Mobile number is required</p>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Agency email
                  </label>
                  <input
                    type="text"
                    placeholder="Enter agency email "
                    className={`${validationerror && !agencyEmail || validationerror && agencyEmail.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => {
                      setAgencyEmail(e.target.value);
                      validateEmail(e);
                    }}
                    value={agencyEmail}
                  />
                  {
                    validationerror && !agencyEmail ?
                      (<p className="show_invalid_text" >Agency email id is required</p>)
                      : (emailError ? (<p className="show_invalid_text" >Agency email id is not valid </p>):
                        (
                          validationerror && agencyEmail.trim() === '' &&
                          (<p className="show_invalid_text" >Agency email must include chracter </p>)
                        )
                      )
                  }
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="Enter agency password  "
                    className={`${validationerror && !password ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  {
                    validationerror && !password ?
                      (<p className="show_invalid_text" >Agency password is required</p>)
                      :
                      (
                        validationerror && password.trim() === '' &&
                        (<p className="show_invalid_text" >Agency password must include chracter </p>)

                      )
                  }

                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Code limit
                  </label>
                  <input
                    type="number"
                    placeholder="Please enter code limit"
                    className={`${validationerror && !codeAppliedOn ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setCodeAppliedOn(e.target.value)}
                    value={codeAppliedOn}
                  />
                  {
                    validationerror && !codeAppliedOn &&
                    <p className="show_invalid_text" > Code limit is required</p>
                  }
                  {
                    validationerror && codeAppliedOn === "0" &&
                    <p className="show_invalid_text" > Code limit can't be 0</p>
                  }

                </div>
              </div>


              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    id="close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit"  >Add  agency</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/*   Edit Agency Code Popup */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRightEdit"
        aria-labelledby="offcanvasRightEditLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightEditLabel">
            Edit agency details
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={reset}
          ></button>
        </div>
        <div className="offcanvas-body">

          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleupdateSubmit}>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Agency name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your agency name"
                    className={`${validationerror && !agencyName || validationerror && agencyName.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setAgencyName(e.target.value)}
                    value={agencyName}
                  />
                  {
                    validationerror && !agencyName ?
                      (<p className="show_invalid_text" >Agency's name is required</p>)
                      :
                      (
                        validationerror && agencyName.trim() === '' &&
                        (<p className="show_invalid_text" >Agency's name must include chracter </p>)
                      )
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Agency email
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your agency email"
                    className={`${validationerror && !agencyEmail || validationerror && agencyEmail.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => {
                      setAgencyEmail(e.target.value);
                      validateEmail(e);
                    }}
                    value={agencyEmail}
                  />
                  {
                    validationerror && !agencyEmail ?
                      (<p className="show_invalid_text" >Agency's email is not required</p>)
                      : (emailError ? (<p className="show_invalid_text" >Agency's email id is not valid </p>) :
                        (
                          validationerror && agencyEmail.trim() === '' &&
                          (<p className="show_invalid_text" >Agency's email must include chracter </p>)
                        )
                      )
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Agency mobile number
                  </label>
                  <input
                    type="number"
                    placeholder="Enter your agency mobile number"
                    className={`${validationerror && !mobileNumber ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setMobileNumber(e.target.value)}
                    value={mobileNumber}
                  />
                  {
                    validationerror && !mobileNumber &&
                    <p className="show_invalid_text" > Mobile number is required</p>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Code applied on
                  </label>
                  <input
                    type="number"
                    placeholder="Enter your code applied on"
                    className={`${validationerror && !codeAppliedOn ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setCodeAppliedOn(e.target.value)}
                    value={codeAppliedOn}
                  />
                  {
                    validationerror && !codeAppliedOn &&
                    <p className="show_invalid_text" > Code applied on is required</p>
                  }
                  {
                    validationerror && codeAppliedOn === "0" &&
                    <p className="show_invalid_text" > Code applied can't be 0</p>
                  }
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    id="close_btn2"
                  >
                    Cancel
                  </button>
                  <button type='submit'>
                    Update agency details
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove "{agencyName}" agency </h1>
            <p>
              Do you really want to remove agency?
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button
                onClick={() => deleteAgency()}
                className="btn thali_btn"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
}
