import React, { useState, useEffect } from "react";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import "../Dashboard.css";
import remove from "../../../img/remove.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Modal from "react-modal";
import {
  deletecategorylist,
  getsubcategorylist,
  api_url, getCategoryMenulist, getAgencyMenuList, getAgencyPopupMenuList
} from "../../../Redux/Reducer/AuthReducer";
import axios from "axios";
import ResponsivePagination from 'react-responsive-pagination';
import loadergif from '../../../img/landing/loaderBlue.gif';

export default function SubCategory() {
  const dispatch = useDispatch();
  const [Categorydata, setCategoryData] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [nametitle, setNameTitle] = useState(null);
  const [Description, setDescription] = useState("");
  const [cleartype, setCleartype] = useState([]);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [validationerror, setValidationerror] = useState(false)
  const [totalcount, setTotalCount] = useState('');
  const [paginationPage, setPaginationPage] = useState(1)
  const [categoryPage, setCategoryPage] = useState(0)
  const [MainCategorydata, setMainCategoryData] = useState([]);

  const [MainCategorydatalist, setMainCategoryDatalist] = useState([]);
  const [subCategoryId, setsubCategoryId] = useState();
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null)
  const [isSuccess, setIsSuccess] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [showData, setShowData] = useState(10)
  const [agencyData, setAgencyData] = useState()
  const [agencyDataList, setAgencyDataList] = useState("All")
  const [agencyId, setAgencyId] = useState()
  const [agencyPage, setAgencyPage] = useState(0)
  const [agencyMenuId, setAgencyMenuId] = useState()
  const [agencyMenuPage, setAgencyMenuPage] = useState(0)
  const [agencypopupDataList, setAgencyPopupDataList] = useState("All")
  const [agencyPopupData, setAgencyPopupData] = useState()
  const [loading, setLoading] = useState(false)
  var userId = localStorage.getItem("user_id");
  var admin = localStorage.getItem("admin");
  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  const getsubcategoryData = () => {
    setLoading(true)
    dispatch(getsubcategorylist({ categoryId, search, currentPage, showData, agencyId })).then((res) => {
      if (res?.payload?.success == true) {
        setCategoryData(res?.payload?.data);
        setTotalCount(res?.payload?.count)
        setIsSuccess(res?.payload?.success)
        setLoading(false)
      } else {
        setIsSuccess(res?.payload?.success)
        setError(res?.payload?.message)
        setTotalCount(0)
        setLoading(false)
      }
    });
  };



  const deletecategoryData = () => {
    dispatch(deletecategorylist(subCategoryId)).then((res) => {
      if (res?.payload?.success == true) {
        toast.success("Sub-category deleted successfully.");
        RemovecloseModal();
        getsubcategoryData();
      } else {
       
        toast.error(res?.payload?.message);
        

      }
    });
  };


  const reset = () => {
    setNameTitle("");
    setDescription('')
    manicategorylist()
    setValidationerror(false)
    setAgencyPopupDataList("All")
    setAgencyMenuId("1")
    setMainCategoryDatalist('')
    setCategoryId('')
  };


  const handleSubmit = (e) => {
    
    e.preventDefault();

    if (!nametitle || nametitle.trim() === '' || !Description || Description.trim() === ''  || !categoryId
    ) {
      setValidationerror(true)
      return false
    }
    else {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          'Authorization': `jwt ${token}`
        }
      };
      const postData = {
        categoryId: MainCategorydatalist.value,
        title: nametitle,
        description: Description,
        // user_id: agencyMenuId ? agencyMenuId : userId,
        user_id:  userId,
        is_admin: admin
      };
      axios
        .post(api_url + `category/addSubCategory`, postData, config)
        .then((res) => {
         
          if (res?.data.success == true) {
            toast.success(res?.data?.message);
            setDescription("");
            setNameTitle('');
            const modal_close = document.getElementById("close_btn").click();
            setValidationerror(false)
            getsubcategoryData();
          } else {
            toast.error(res?.data?.message);
          }
        });

    };
  }


  const handleupdateSubmit = (e) => {
    e.preventDefault();


    if (!nametitle || nametitle.trim() === '' || !Description || Description.trim() === ''  || !categoryId


    ) {
      setValidationerror(true)
      return false
    }
    else {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          'Authorization': `jwt ${token}`
        }
      };

      const postData = {
        categoryId: categoryId,
        title: nametitle,
        description: Description,
        subCategoryId: subCategoryId,
        user_id:  userId,
        // user_id: agencyMenuId ? agencyMenuId : userId,
        is_admin: admin
        
      };
      axios
        .post(api_url + `category/editSubCategory`, postData, config)
        .then((res) => {
          if (res?.data.success == true) {
            toast.success(res?.data.message);
            const modal_close2 = document.getElementById("close_btn2").click();
            setValidationerror(false)
            getsubcategoryData();

          } else {
            toast.error(res?.data.message);
          }
        });

    };
  }


  const dataget = (item) => {
    
    setNameTitle(item?.title);
    setMainCategoryDatalist(item?.category_name);
    setDescription(item?.description);
    setCategoryId(item?.parent_id)
    setsubCategoryId(item?.id)
    manicategorylist()
    getsubcategoryData()
    setAgencyPopupDataList(item?.agency_name)
    setAgencyMenuId(item?.user_id)
  };


  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getcategoryDataalllist = totalcount ? totalcount : "";

  useEffect(() => {
    manicategorylist()
  }, [agencyMenuId])
  


  const manicategorylist = () => {
    dispatch(getCategoryMenulist({ categoryPage, agencyMenuId })).then((res) => {
      if (res?.payload?.success == true) {
        setMainCategoryData(res?.payload?.data);
       
      } else {
        setError(res?.payload?.message)
      }
    });
  };

  const MainCategory = MainCategorydata?.map((item) => {
    return {
      label: item.title,
      value: item.id,
    };
  });


  useEffect(() => {
    getsubcategoryData();
  }, [search, currentPage, showData, totalcount, agencyId]);


  const pageSize = 10
  const totalPages = Math.ceil(totalcount / showData);
  const handlePageChange = (page) => {
    setCurrentPage(page - 1)
    setPaginationPage(page)
  }
  const handleEditChange = (e) => {
    setMainCategoryDatalist(e.label)
    setCategoryId(e.value)
  }

  const handleCategory = (e) => {
    setMainCategoryDatalist(e)
    setCategoryId(e.value)
  }

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (parseInt(selectedValue) == 10) {
      setShowData(parseInt(selectedValue));
    } else if (parseInt(selectedValue) == 20 && totalcount < 20) {
      setShowData(parseInt(selectedValue));
      setCurrentPage(0)
      setPaginationPage(1)
    } else if (parseInt(selectedValue) == 50 && totalcount < 50) {
      setShowData(parseInt(selectedValue));
      setCurrentPage(0)
      setPaginationPage(1)
    } else {
      setShowData(parseInt(selectedValue));
    }
  };
// Agency Dropdown
  const getAgencyListData = () => {
    dispatch(getAgencyMenuList({ agencyPage, agencyId })).then((res) => {
      if (res?.payload?.success == true) {
        setIsSuccess(res?.payload?.success)
        setAgencyData(res?.payload?.data);
      } else {
        setIsSuccess(res?.payload?.success)
        setError(res?.payload?.message)
      }
    });
  };

  const handleAgencyEditChange = (e) => {
    setAgencyDataList(e.label)
    setAgencyId(e.value)
  };

  useEffect(() => {
    getAgencyListData();
  }, [agencyPage]);

  let agencyList = [];

  if (agencyData) {
    agencyList = agencyData.map((item) => {
      return {
        label: item?.agency_name,
        value: item?.id,
      };
    });

    agencyList.unshift({ label: "All", value: admin });
  }
  // Popup Agency

  useEffect(() => {

    getAgencyPopupListData()
  }, [agencyMenuPage]);

  const getAgencyPopupListData = () => {
    dispatch(getAgencyPopupMenuList({ agencyMenuPage, agencyMenuId })).then((res) => {
      if (res?.payload?.success == true) {
        setIsSuccess(res?.payload?.success)
        setAgencyPopupData(res?.payload?.data);
      } else {
        setIsSuccess(res?.payload?.success)
        setError(res?.payload?.message)
      }
    });
  };

  const handlePopupEditChange = (e) => {
    setAgencyPopupDataList(e.label)
    setAgencyMenuId(e.value)
  };

  useEffect(() => {
    getAgencyPopupListData();
  }, [agencyPage]);

  let agencyPopupList = [];

  if (agencyPopupData) {
    agencyPopupList = agencyPopupData.map((item) => {
      return {
        label: item?.agency_name,
        value: item?.id,
      };
    });
    
    agencyPopupList.unshift({ label: "All", value: admin });
  }

  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      {" "}
                      <h1 className="title_heading">
                        Sub category{" "}{getcategoryDataalllist ? "("+getcategoryDataalllist+")" : ''}
                      </h1>{" "}
                    </div>

                    <div className="Thali_page_btn">
                      <div className="New_section">

                        <button
                          className="btn thali_btn"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          onClick={reset}
                        >
                          Add new sub category
                        </button>
                        &nbsp; &nbsp;
                        &nbsp;{" "}
                      </div>
                      &nbsp;
                    </div>
                  </div>
                </div>

                <div className="mb-3 menu-search-container">
                  <div className="dropdown mx-3 mb-3" >
                    <label for='number-dd'>Show</label>
                    <div>
                      <select onChange={handleSelectChange} className="select-number" name='number'>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                      </select>
                    </div>
                  </div>
                  <div className="mx-3 mb-3 search-input">
                    <div className="search-container">
                      <label for='number-dd'>Search</label>

                      <div className="thali_count_list " >
                        <div class="form-group has-search">
                          <span className="fa fa-search form-control-feedback"></span>
                          <input type="search" class="form-control" id="floatingInput" placeholder="Search sub category" onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>
                      </div>
                    </div>
                  </div>
          {/*         {admin.includes("1") && (
                    <div className="mx-3 mb-3 dropdown custom-select">
                      <label for="number-dd">Agency list</label>
                      <Select
                        placeholder="Select agency"
                        value={agencyList && agencyList.find((option) => option.label === agencyDataList)}
                        onChange={(e) => {
                          handleAgencyEditChange(e);
                        }}
                        options={agencyList}
                        defaultValue={"All"}
                        className="w-100 "
                      />
                    </div>
                  )}
                  */}
                </div>



                <div className="thali_table_section">
                {loading ? (
         
         <div className='Loading-img'><img src={loadergif} className='' /></div>

       ) : (
         <div></div>
       )
       }
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>

                        <th scope="col" >
                          <span className="thali_table">  Category name   &nbsp; &nbsp;
                          </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Sub-category name</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Description</span>
                        </th>
                        {/* {
                          admin.includes(1) &&  <th scope="col">
                            <span className="thali_table">Agency name</span>
                          </th>

                        } */}
                        <th scope="col">
                          <span className="thali_table">Action</span>
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>

                      {

                        isSuccess === true ?



                          (Categorydata && (

                            <>
                              {Categorydata?.map((item) => {

                                return (
                                  <tr key={item.id}>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.category_name}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item.title ? item.title : "-"}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.description ? item?.description : "-"}
                                        </li>
                                      </ul>
                                    </th>
                                    {/* {
                                    admin.includes("1") && 
                                    <th scope="row">
                                    <ul className="thali_menu_list">
                                      <li>{item.agency_name ? item.agency_name : "-"}</li>
                                    </ul>
                                  </th>

                                   } */}
                                    <th scope="row">
                               
                                      <ul className="thali_menu_list">
                                        <li>
                                          <button className="borderless-button" onClick={() => dataget(item)} >
                                            <a

                                              class="dropdown-item"
                                              data-bs-toggle="offcanvas"
                                              data-bs-target="#offcanvasRightEdit"
                                              aria-controls="offcanvasRightEdit"
                                            >
                                              <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#004874" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#004874" />
                                              </svg>
                                            </a>
                                          </button>

                                          <button onClick={() => {
                                            RemoveModal()
                                            dataget(item)
                                          }} className="borderless-button">

                                            <svg width="64px" height="64px" viewBox="-184.32 -184.32 1392.64 1392.64" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#" stroke="#" stroke-width="0.01024">
                                              <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                              <g id="SVGRepo_iconCarrier">
                                                <path d="M960 160h-291.2a160 160 0 0 0-313.6 0H64a32 32 0 0 0 0 64h896a32 32 0 0 0 0-64zM512 96a96 96 0 0 1 90.24 64h-180.48A96 96 0 0 1 512 96zM844.16 290.56a32 32 0 0 0-34.88 6.72A32 32 0 0 0 800 320a32 32 0 1 0 64 0 33.6 33.6 0 0 0-9.28-22.72 32 32 0 0 0-10.56-6.72zM832 416a32 32 0 0 0-32 32v96a32 32 0 0 0 64 0v-96a32 32 0 0 0-32-32zM832 640a32 32 0 0 0-32 32v224a32 32 0 0 1-32 32H256a32 32 0 0 1-32-32V320a32 32 0 0 0-64 0v576a96 96 0 0 0 96 96h512a96 96 0 0 0 96-96v-224a32 32 0 0 0-32-32z" fill="#EB5548" />
                                                <path d="M384 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM544 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM704 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0z" fill="#EB5548" />
                                              </g>
                                            </svg>
                                          </button>

                                        </li>

                                      </ul>
                                    </th>
                                  </tr>
                                )
                              })}
                            </>
                          )
                          )
                          :
                          search != '' ? (
                            <tr >

                              <td colSpan="4" >
                                <ul className="NotFound text-center">
                                  <li>
                                    {error}
                                  </li>
                                </ul>


                              </td>


                            </tr>
                          )
                            : null
                      }


                    </tbody>
                  </table>
                  {/* Pagination */}
                  {
                    (totalcount > 10 && showData === 10) ||
                      (totalcount > 20 && showData === 20) ||
                      (totalcount > 50 && showData === 50)
                      ? (
                        <ResponsivePagination
                          total={totalPages}
                          current={paginationPage}
                          onPageChange={page => handlePageChange(page)}
                        />
                      )
                      : null
                  }
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>


      {/* Add Sub Category */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Add sub category
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
     
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
          <div className="Form_box" >
          {/* {admin.includes("1") && (
                    <div className="mb-3 dropdown">
                      <label for="inputPassword4" className="form-label">
                    Agency list
                  </label>
                  <Select
                        placeholder="Select agency"
                        value={agencyList && agencyList.find((option) => option.label === agencypopupDataList)}
                        onChange={(e) => {
                          handlePopupEditChange(e);
                        }}
                        options={agencyList}
                        defaultValue={"All"}
                        className="w-100"
                      />
                    </div>
                  )} */}
                  </div>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-12">
                <div className="Form_box">
                  
                  <label for="inputPassword4" className="form-label">
                    Category
                  </label>

                  <Select
                    onChange={(e) => handleCategory(e)}
                    options={MainCategory}
                    value={MainCategorydatalist}
                    placeholder="Select category "
                    defaultValue={cleartype}
                    className={`${validationerror && !categoryId ? "validation_valid dropdown" : "dropdown"}`}
                  />
                  {
                    validationerror && !categoryId &&
                    <p className="show_invalid_text" >Category is required</p>
                  }
                </div>
              </div>
              <div className="col-md-12">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Sub-category title
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your sub-category title "
                    className={`${validationerror && !nametitle || validationerror && nametitle.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setNameTitle(e.target.value)}
                    value={nametitle}
                  />
                  {
                    validationerror && !nametitle ?
                      (<p className="show_invalid_text" >Sub category title is required</p>)
                      :
                      (
                        validationerror && nametitle.trim() === '' &&
                        (<p className="show_invalid_text" >Sub category is required </p>)

                      )
                  }
                </div>
              </div>

              <div className="col-md-12">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Sub category description
                  </label>


                  <textarea 
                className={`${validationerror && !Description  || validationerror && Description.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                  placeholder="Enter sub category description"
                    id="exampleFormControlTextarea1" cols="20" rows="8"
                    onChange={(e) => setDescription(e.target.value)}
                    value={Description} />
                      {
                    validationerror && !Description ?
                      (<p className="show_invalid_text" >Description is required</p>)
                      :
                      (
                        validationerror && Description.trim() === '' &&
                        (<p className="show_invalid_text" >Description is required </p>)

                      )
                  }
                </div>
              </div>


              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    id="close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit" >Add  sub  category</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*   Edit Sub Category Popup */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRightEdit"
        aria-labelledby="offcanvasRightEditLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightEditLabel">
            Edit sub category
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
      
        <div className="offcanvas-body">

          <div className="Dish_Name_list">
          {/* <div className="Form_box" >
          {admin.includes("1") && (
                    <div className="mb-3 dropdown">
                      <label for="inputPassword4" className="form-label">
                    Agency list
                  </label>
                  <Select
                        placeholder="Select agency"
                        value={agencyList && agencyList.find((option) => option.label === agencypopupDataList)}
                        onChange={(e) => {
                          handlePopupEditChange(e);
                        }}
                        options={agencyList}
                        defaultValue={"All"}
                        className="w-100"
                      />
                    </div>
                  )}
                  </div> */}
            <form className="row g-3">
              <div className="Form_box">
                <label for="inputDishName" className="form-label">
                  Select category
                </label>
                <Select
                  onChange={(e) => { handleEditChange(e) }}
                  options={MainCategory}
                  value={MainCategory.filter(function (option) {
                    return option.label === MainCategorydatalist;
                  })}

                  placeholder="Select category "
                  defaultValue={cleartype}
                  className={`${validationerror && !categoryId ? "validation_valid dropdown" : "dropdown"}`}
                />
                {
                  validationerror && !categoryId &&
                  <p className="show_invalid_text" > Category name is required</p>
                }
              </div>

              <div className="col-md-12">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Sub category title
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your sub-category title"
                    className={`${validationerror && !nametitle || validationerror && nametitle.trim() === '' ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setNameTitle(e.target.value)}
                    value={nametitle}
                  />
                {
                    validationerror && !nametitle ?
                      (<p className="show_invalid_text" >Sub category title is required</p>)
                      :
                      (
                        validationerror && nametitle.trim() === '' &&
                        (<p className="show_invalid_text" >Sub category title is required</p>)

                      )
                  }
                </div>
              </div>
              <div className="col-md-12">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Sub category description
                  </label>

                  <textarea
                 className={`${validationerror && !Description || validationerror && Description.trim() === '' ? "validation_valid form-control" : "form-control"}`}

                   placeholder="Sub category description"
                    id="exampleFormControlTextarea1" cols="20" rows="8"
                    onChange={(e) => setDescription(e.target.value)}
                    value={Description} />
                       {
                    validationerror && !Description ?
                      (<p className="show_invalid_text" >Sub category title is required</p>)
                      :
                      (
                        validationerror && Description.trim() === '' &&
                        (<p className="show_invalid_text" >Sub category title is required </p>)
                      )
                  }
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    id="close_btn2"
                  >
                    Cancel
                  </button>
                  <button onClick={handleupdateSubmit}>
                    Update sub category
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove "{nametitle}" sub category</h1>
            <p>
              Do you really want to remove sub category?
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button
                onClick={() => deletecategoryData()}
                className="btn thali_btn"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
}
