import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import "../Dashboard.css";
import Pavbhaji from "../../../img/noimg.png";
import remove from "../../../img/remove.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import Angry from '../../../img/angry.gif'
import Disgust from '../../../img/disgust.gif'
import Surprise from '../../../img/surprise.gif'
import Happy from '../../../img/Happy.gif'
import Neutral from '../../../img/Neutral.gif'
import Sad from '../../../img/sad.gif'
import Fear from '../../../img/Fear.gif'
import {
  deleteVideoData,
  emotionByVideo, emotionVideo,deleteVideoViewOfUser
} from "../../../Redux/Reducer/AuthReducer";
import ResponsivePagination from 'react-responsive-pagination';
import loadergif from '../../../img/landing/loaderBlue.gif';

export default function VideoList() {
  const dispatch = useDispatch();
  const data = JSON.parse(localStorage.getItem('videoEmotionId'));
  
  const [title, setTitle] = useState(null);
  const [video_id, setVideoID] = useState("");
  const [id, setId] = useState("");
  const [videoEmotionData, setVideoEmotionData] = useState([]);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [totalcount, setTotalCount] = useState('');
  const [error, setError] = useState(null)
  const [isSuccess, setIsSuccess] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [paginationPage, setPaginationPage] = useState(1)
  const [search, setSearch] = useState('');
  const [emotionOfVideoData, setEmotionOffVideoData] = useState('');
  const [showData, setShowData] = useState(10)
  const [loading, setLoading] = useState(false)
  var userId = localStorage.getItem("user_id");
  var admin = localStorage.getItem("admin");


  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }






  const deleteVideo = () => {
    dispatch(deleteVideoData(id)).then((res) => {
      if (res?.payload?.success == true) {
        toast.success(res?.payload?.message);
        RemovecloseModal();
        getEmotionOnVideo()

      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  
  const deleteVideoViewEmotionOfUser = () => {
    dispatch(deleteVideoViewOfUser(id,data)).then((res) => {
      if (res?.payload?.success == true) {
        toast.success(res?.payload?.message);
        RemovecloseModal();
        getEmotionOnVideo()

      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const dataget = (item) => {
    
    setId(item?.id)
    setVideoID(item?.id)
    setTitle(item?.title)
  };


  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getcategoryDataalllist = totalcount ? totalcount : "";




  const getEmotionOnVideo = () => {
    if (data) {
      setLoading(true)
      dispatch(emotionByVideo({ data, search, currentPage })).then((res) => {
        if (res?.payload?.success == true) {
          setIsSuccess(res?.payload?.success)
          setVideoEmotionData(res?.payload?.data)
          
          setTotalCount(res?.payload?.data.length)
          setLoading(false)
        } else {
          setIsSuccess(res?.payload?.success)
          setError(res?.payload?.message)
          setTotalCount(0)
          setLoading(false)
        }
      });
    }
  }


  const totalPages = Math.ceil(totalcount / showData);
  const handlePageChange = (page) => {
    setCurrentPage(page - 1)
    setPaginationPage(page)
  }
  useEffect(() => {
    getEmotionOnVideo()
  }, [search, currentPage]);

  const getEmotionOffVideo = () => {
    if (id) {
      dispatch(emotionVideo({ id, data,currentPage, showData })).then((res) => {
        if (res?.payload?.success == true) {
          setEmotionOffVideoData(res?.payload?.data)
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  }

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (parseInt(selectedValue) == 10) {
      setShowData(parseInt(selectedValue));
    } else if (parseInt(selectedValue) == 20 && totalcount < 20) {
      setShowData(parseInt(selectedValue));
      setCurrentPage(0)
      setPaginationPage(1)
    } else if (parseInt(selectedValue) == 50 && totalcount < 50) {
      setShowData(parseInt(selectedValue));
      setCurrentPage(0)
      setPaginationPage(1)
    } else {
      setShowData(parseInt(selectedValue));
    }
  };

  useEffect(() => {
    getEmotionOffVideo()
  }, [id, currentPage]);
  function ParagraphWithShowMore({ text }) {
    const words = text.split(" ");
    const [showMore, setShowMore] = useState(false);

    const displayText = showMore ? text : words.slice(0, 30).join(" ");

    const toggleShowMore = () => {
      setShowMore(!showMore);
    };

    return (
      <div>
        <p style={paragraphStyle} >{displayText}</p>
        {words.length > 30 && (
          <button type="button" className="show-more-btn" onClick={toggleShowMore}>
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
    );
  }
  const paragraphStyle = {
    wordWrap: "break-word",
    maxWidth: "100%",
    marginTop: "-1px",
  };
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="mb-3 menu-search-container">
                  <div className="dropdown mx-3 mb-3">
                    <div className="Back-page">
                      {" "}
                      <Link to={'/User'}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                            fill="#000000"
                          />
                        </svg>
                      </Link>
                      <h1 className="title_heading me-3">
                        Emotion on videos{" "}{getcategoryDataalllist ? "("+getcategoryDataalllist+")" : ''}
                      </h1>{" "}
                    </div>
                  </div>
                </div>
                <div className="mb-3 menu-search-container">
                  <div className="dropdown mx-3 mb-3" >
                    <label for='number-dd'>Show</label>
                    <div>
                      <select onChange={handleSelectChange} className="select-number" name='number'>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                      </select>
                    </div>
                  </div>
                  <div className="mx-3 mb-3 search-input">
                    <div className="search-container">
                      <label for='number-dd'>Search</label>

                      <div className="thali_count_list " >
                        <div class="form-group has-search">
                          <span className="fa fa-search form-control-feedback"></span>
                          <input type="search" class="form-control" id="floatingInput" placeholder="Search user" onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="thali_table_section">
                         {loading ? (
         
         <div className='Loading-img'><img src={loadergif} className='' /></div>

       ) : (
         <div></div>
       )
       }
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>

                        <th scope="col" >
                          <span className="thali_table">  Thumbnail   &nbsp; &nbsp;
                          </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Channel name </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Description</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Title</span>
                        </th>


                        <th scope="col">
                          <span className="thali_table" >Link</span>
                        </th>

                        <th scope="col">
                          <span className="thali_table">Video id</span>
                        </th>

                        <th scope="col">
                          <span className="thali_table">Activity</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {

                        isSuccess === true ?

                          (videoEmotionData && (
                            <>
                              {videoEmotionData?.map((item) => {
                          
                                return (
                                  <tr key={item.id}>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          <img
                                            src={
                                              item?.thumbnail
                                                ? item.thumbnail
                                                : Pavbhaji
                                            }
                                            className="img-firud ingredients-img"
                                          />
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.channel_name ? item?.channel_name : "-"}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                        {item.Description != "" || item.Description != null ? <ParagraphWithShowMore text={item.description} /> : ""}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.title ? item?.title : "-"}
                                        </li>
                                      </ul>
                                    </th>
                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.link ? item?.link : "-"}
                                        </li>
                                      </ul>
                                    </th>
                                    <th scope="row">
                                      <ul className="thali_menu_list">
                                        <li>
                                          {item?.video_id ? item?.video_id : "-"}
                                        </li>
                                      </ul>
                                    </th>

                                    <th scope="row">
                                      <button onClick={() => dataget(item)} className="button-container mt-2 mx-2" >
                                        <a
                                          class="dropdown-item"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasRightEdit"
                                          aria-controls="offcanvasRightEdit"
                                        >


                                          All emotion on video
                                        </a>
                                      </button>

                                    </th>
                                    <th scope="row">

                                      <button onClick={() => {
                                        RemoveModal()
                                        dataget(item)
                                      }} className="borderless-button">

                                        <svg width="64px" height="64px" viewBox="-184.32 -184.32 1392.64 1392.64" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#" stroke="#" stroke-width="0.01024">
                                          <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                          <g id="SVGRepo_iconCarrier">
                                            <path d="M960 160h-291.2a160 160 0 0 0-313.6 0H64a32 32 0 0 0 0 64h896a32 32 0 0 0 0-64zM512 96a96 96 0 0 1 90.24 64h-180.48A96 96 0 0 1 512 96zM844.16 290.56a32 32 0 0 0-34.88 6.72A32 32 0 0 0 800 320a32 32 0 1 0 64 0 33.6 33.6 0 0 0-9.28-22.72 32 32 0 0 0-10.56-6.72zM832 416a32 32 0 0 0-32 32v96a32 32 0 0 0 64 0v-96a32 32 0 0 0-32-32zM832 640a32 32 0 0 0-32 32v224a32 32 0 0 1-32 32H256a32 32 0 0 1-32-32V320a32 32 0 0 0-64 0v576a96 96 0 0 0 96 96h512a96 96 0 0 0 96-96v-224a32 32 0 0 0-32-32z" fill="#EB5548" />
                                            <path d="M384 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM544 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0zM704 768V352a32 32 0 0 0-64 0v416a32 32 0 0 0 64 0z" fill="#EB5548" />
                                          </g>
                                        </svg>
                                      </button>

                                    </th>
                                  </tr>
                                );
                              })}
                            </>
                          ))
                          :

                          (
                            search != '' ? (
                              <tr >

                                <td colSpan="8" >
                                  <ul className="NotFound text-center">
                                    <li>
                                      {error}
                                    </li>
                                  </ul>


                                </td>
                              </tr>
                            )
                              :
                              search == "" && isSuccess === false && (
                                <tr >

                                  <td colSpan="8" >
                                    <ul className="NotFound text-center">
                                      <li>
                                        {error}
                                      </li>
                                    </ul>


                                  </td>
                                </tr>
                              )
                          )

                      }

                    </tbody>
                  </table>
                  {/* Pagination */}
                  {
                    totalcount > 10 &&
                    <ResponsivePagination
                      total={totalPages}
                      current={paginationPage}
                      onPageChange={page => handlePageChange(page)}
                    />
                  }
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove </h1>
            <p>
              Do you really want to remove? 
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button
                onClick={() => deleteVideoViewOfUser(id,data)}
                className="btn thali_btn"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* All Emotion on video */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRightEdit"
        aria-labelledby="offcanvasRightEditLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightEditLabel">
            All emotion on video
          </h5>

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"

          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
            <form className="row g-3" >
              <section className="main_section">
                <div className="custam">
                  <div className="main_section2">
                    <div className="ExportInventory thali_section Thali_page">
                      <div className="thali_table_section">
                        <table class="table table-bordered">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">
                                <div className="center-content">
                                  <span className="center-cell">Serial no</span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="center-content">
                                  <span className="center-cell">Dominant emotion</span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="center-content">
                                  <span className="center-cell">Emotion count </span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {emotionOfVideoData && (
                              <>
                                {emotionOfVideoData?.map((item, index) => {
                                  index += 1;
                                  return (
                                    <tr key={item.id}>
                                      <td className="center-cell">{index}</td>
                                      <td className="center-cell">

                                        {item?.dominant_emotion && item?.dominant_emotion === "neutral" ? (
                                          <div className="emotion-container">
                                            <img src={Neutral} alt="Neutral" className="img-fluid emoji-icon" />
                                            <span className="center-cell">Neutral </span>
                                          </div>
                                        )
                                          :

                                          item?.dominant_emotion === "surprise" ? (
                                            <div className="emotion-container">
                                              <img src={Surprise} alt="Surprise" className="img-fluid emoji-icon "  />
                                              <span className="center-cell">Surprise </span>
                                            </div>
                                          )
                                            :

                                            item?.dominant_emotion === "sad" ? (
                                              <div className="emotion-container">
                                                <img src={Sad} alt="Sad" className="img-fluid emoji-icon " />
                                                <span className="center-cell">Sad </span>
                                              </div>
                                            )
                                              :

                                              item?.dominant_emotion === "happy" ? (
                                                <div className="emotion-container">
                                                  <img src={Happy} alt="Happy" className="img-fluid emoji-icon" />
                                                  <span className="center-cell">Happy </span>
                                                </div>
                                              )
                                                :

                                                item?.dominant_emotion === "fear" ? (
                                                  <div className="emotion-container">
                                                    <img src={Fear} alt="Fear" className="img-fluid emoji-icon" />
                                                    <span className="center-cell">Fear </span>
                                                  </div>
                                                )
                                                  :

                                                  item?.dominant_emotion === "disgust" ? (
                                                    <div className="emotion-container">
                                                      <img src={Disgust} alt="Disgust" className="img-fluid emoji-icon " />
                                                      <span className="center-cell">Disgust </span>
                                                    </div>
                                                  )
                                                    :

                                                    item?.dominant_emotion === "angry" ? (
                                                      <div className="emotion-container">
                                                        <img src={Angry} alt="Angry" className="img-fluid emoji-icon" />
                                                        <span className="center-cell">Angry </span>
                                                      </div>
                                                    )

                                                      : (
                                                        "-"
                                                      )}
                                      </td>
                                      <td className="center-cell">{item?.emotion_count ? item.emotion_count : "-"}</td>
                                    </tr>
                                  );
                                })}
                              </>
                            )}

                          </tbody>
                        </table>
                        {/* Pagination */}

                      </div>

                    </div>
                  </div>
                </div>
              </section>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    id="close_btn2"
                  >
                    Close
                  </button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}



