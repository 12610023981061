export const EyeOff = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <g fill="#0F0F0F">
            <path d="M4.495 7.44c-.948.678-1.717 1.402-2.306 2.04a3.679 3.679 0 000 5.04C3.917 16.391 7.19 19 12 19c1.296 0 2.48-.19 3.552-.502l-1.662-1.663A10.77 10.77 0 0112 17c-4.033 0-6.812-2.18-8.341-3.837a1.68 1.68 0 010-2.326 12.972 12.972 0 012.273-1.96L4.495 7.442z"></path>
            <path d="M8.533 11.478a3.5 3.5 0 003.983 3.983l-3.983-3.983zM15.466 12.447l-3.919-3.919a3.5 3.5 0 013.919 3.919z"></path>
            <path d="M18.112 15.093a12.99 12.99 0 002.23-1.93 1.68 1.68 0 000-2.326C18.811 9.18 16.032 7 12 7c-.64 0-1.25.055-1.827.154L8.505 5.486A12.623 12.623 0 0112 5c4.811 0 8.083 2.609 9.81 4.48a3.679 3.679 0 010 5.04c-.58.629-1.334 1.34-2.263 2.008l-1.435-1.435zM2.008 3.422a1 1 0 111.414-1.414L22 20.586A1 1 0 1120.586 22L2.008 3.422z"></path>
          </g>
        </svg>
      );
}

export const EyeOn = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#000"
            fillRule="evenodd"
            d="M6.301 15.577C4.778 14.268 3.691 12.773 3.18 12c.51-.773 1.598-2.268 3.121-3.577C7.874 7.072 9.816 6 12 6c2.184 0 4.126 1.072 5.699 2.423 1.523 1.309 2.61 2.804 3.121 3.577-.51.773-1.598 2.268-3.121 3.577C16.126 16.928 14.184 18 12 18c-2.184 0-4.126-1.072-5.699-2.423zM12 4C9.148 4 6.757 5.395 4.998 6.906c-1.765 1.517-2.99 3.232-3.534 4.064a1.876 1.876 0 000 2.06c.544.832 1.769 2.547 3.534 4.064C6.758 18.605 9.148 20 12 20c2.852 0 5.243-1.395 7.002-2.906 1.765-1.517 2.99-3.232 3.534-4.064.411-.628.411-1.431 0-2.06-.544-.832-1.769-2.547-3.534-4.064C17.242 5.395 14.852 4 12 4zm-2 8a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8z"
            clipRule="evenodd"
          ></path>
        </svg>
      );
}