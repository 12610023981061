import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate, Navigate, Outlet } from "react-router-dom";
import "./App.css";
import "./Components/Commonstyle.css";
import Login from "./Components/Login/Login";
import { ToastContainer, toast } from "react-toastify";
import Category from "./Components/Dashboard/Categorypage/Category";
import SubCategory from "./Components/Dashboard/SubCategoryPage/SubCategory";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Agency from "./Components/Dashboard/Agency/Agency";
import FeedbackQA from "./Components/Dashboard/FeddbackQA/FeedbackQA";
import User from "./Components/Dashboard/User/User";
import VideoList from "./Components/Dashboard/User/VideoList";
import Home from "./Components/Dashboard/HomePage/Home";
import Youtube from "./Components/Dashboard/YoutubePage/Youtub";
import Policy from "./Components/Policy/policy";
import DeleteAccount from "./Components/Delete_account/Delete_account";
import HomePage from "./Components/Home/HomePage";
import PublicRoute from "./Components/PublicRoute/PublicRoute";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [venaqrr, setVenaqrr] = useState([]);
  const history = useNavigate();
  // useEffect(() => {
  //   const access_token = localStorage.getItem("access_token");

  //   if (!access_token || access_token === "null") {
  //     history("/");
  //   }
  // }, [history]);

  const getvenderlist = (data) => {
    setVenaqrr(data);
  };

  return (
    <>
      <div>
        <Routes>
          <Route exact path="/login" element={<Login />} />

          <Route path="/" element={<PublicRoute />}>
            <Route path="/" element={<HomePage />} />
            <Route exact path="/privacy-policy" element={<Policy />} />
            <Route exact path="/delete-account" element={<DeleteAccount />} />
          </Route>
          <Route exact path="/*" element={<HomePage />} />
          {/* <Route exact path="/" element={<HomePage />} />
          <Route exact path="/privacy-policy" element={<Policy />} />
          <Route exact path="/delete-account" element={<DeleteAccount />} /> */}
          {/* <Route exact path="/login" element={<Login />} /> */}

          {/* <Route exact path="/Category" element={<Category />} />
          <Route exact path="/Sub-Category" element={<SubCategory />} /> */}
          {/* <Route exact path="/Video" element={<Video  />} /> */}
          {/* <Route exact path="/Youtube-video" element={<Youtube />} />
          <Route exact path="/Agency" element={<Agency />} />
          <Route exact path="/FeedbackQA" element={<FeedbackQA />} />
          <Route exact path="/User" element={<User />} />
          <Route exact path="/VideoList" element={<VideoList />} /> */}
          {/* <Route exact path="/Home" element={<Home />} /> */}

          <Route path="/" element={<ProtectedRoute />}>
            <Route path="/" element={<Outlet />}>
              <Route path="/" element={<Navigate replace to="Home" />} />\
              <Route exact path="Home" element={<Home />} />
              <Route path="Category" element={<Category />} />
              <Route path="Sub-Category" element={<SubCategory />} />
              <Route exact path="Youtube-video" element={<Youtube />} />
              <Route exact path="Agency" element={<Agency />} />
              <Route exact path="FeedbackQA" element={<FeedbackQA />} />
              <Route exact path="User" element={<User />} />
              <Route exact path="VideoList" element={<VideoList />} />
            </Route>
          </Route>
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
